import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    dataMenu: null, notifications: []
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    SET_MENU: (state, payload) => {
      state.dataMenu = payload;
    },
  },
  actions: {
    getMenu({ commit }) {
      return new Promise(resolve => {
        useJwt
          .get('menu')
          .then(response => {
            commit('SET_MENU', response.data.result);
            resolve(response.data.result);
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
  },
}
