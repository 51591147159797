/* eslint-disable */
export default [
    {
        path: '/dashboard/home',
        name: 'home',
        component: () => import('@/views/dashboard/Home.vue'),
        meta: {
            pageTitle: 'Overview Dashboard',
            breadcrumb: [
            {
                text: 'UI',
            },
            {
                text: 'Dashboard',
                active: true,
            },
            ],
        },
    },
    {
        path: '/admin/catalog_types',
        name: 'catalog_types',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Loại văn bản',
            type: 'catalog_types',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admin/catalog_ranges',
        name: 'catalog_ranges',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Phạm vi',
            type: 'catalog_ranges',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/admin/catalog_where_issued',
        name: 'catalog_where_issued',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Nơi ban hành văn bản',
            type: 'catalog_where_issued',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },
    },


    {
        path: '/admin/catalog_wdetail_issued',
        name: 'catalog_wdetail_issued',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Nơi ban hành chi tiết',
            type: 'catalog_wdetail_issued',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/admin/catalog_fields',
        name: 'catalog_fields',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Lĩnh vực',
            type: 'catalog_fields',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admin/catalog_fdetails',
        name: 'catalog_fdetails',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Lĩnh vực chi tiết',
            type: 'catalog_fdetails',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/admin/catalog_positions',
        name: 'catalog_positions',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Chức vụ người ký',
            type: 'catalog_positions',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },    
    },

    {
        path: '/admin/catalog_status',
        name: 'catalog_status',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Tình trạng văn bản',
            type: 'catalog_status',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },    
    },

    {
        path: '/admin/catalog_sources',
        name: 'catalog_sources',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Nguồn văn bản',
            type: 'catalog_sources',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },    
    },


    {
        path: '/admin/catalog_careers',
        name: 'catalog_careers',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Ngành nghề',
            type: 'catalog_careers',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },    
    },



    {
        path: '/admin/catalog_signers',
        name: 'catalog_signers',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Người ký',
            type: 'catalog_signers',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },    
    },
    {
        path: '/admin/catalog_doc_extent',
        name: 'catalog_doc_extent',
        component: () => import('@/views/admin/MasterData.vue'),
        meta: {
            pageTitle: 'Văn bản mở rộng',
            type: 'catalog_doc_extent',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Master Data',
                    active: true,
                },
            ],
        },    
    },
    {
        path: '/ilaw/data-content',
        name: 'data-content',
        component: () => import('@/views/ilaw/DataContent.vue'),
        meta: {
            pageTitle: 'Quản lý thông tin văn bản',
            breadcrumb: [
                {
                    text: 'ILAW',
                },
                {
                    text: 'ILAW managements',
                    active: true,
                },
            ],
        },
    },
    /*--------------App---------------*/

    {
        path: '/error-404',
        name: 'error-404',
        component: () =>
            import('@/views/others/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/auth/login',
        name: 'auth-login',
        component: () =>
            import('@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    
    {
        path: '/others/coming-soon',
        name: 'misc-coming-soon',
        component: () =>
            import('@/views/others/ComingSoon.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/others/under-maintenance',
        name: 'misc-under-maintenance',
        component: () =>
            import('@/views/others/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/profile',
        name: 'pages-profile',
        component: () =>
            import('@/views/auth/profile/Profile.vue'),
        meta: {
            pageTitle: 'Profile',
            breadcrumb: [{
                text: 'Pages',
            },
            {
                text: 'Profile',
                active: true,
            },
            ],
        },
    },
    // *--------------------------------------------------------------------
    {
        path: '/admin/group-mgmt',
        name: 'group-mgmt',
        component: () => import('@/views/admin/AdminData.vue'),
        meta: {
            pageTitle: 'Quản lý quyền',
            type: 'group-mgmt',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'Group Management',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admin/user-mgmt',
        name: 'user-mgmt',
        component: () => import('@/views/admin/AdminData.vue'),
        meta: {
            pageTitle: 'Quản lý người dùng',
            type: 'user-mgmt',
            breadcrumb: [
                {
                    text: 'Admin',
                },
                {
                    text: 'User Management',
                    active: true,
                },
            ],
        },
    }
]